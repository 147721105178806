import React, { useEffect, useState } from "react";
import { filterByCategory, searchCards, sortCards } from "../../util/helpers";
import { StacksTemplate } from "../../templates/stacks"
import { graphql } from "gatsby";
import lunr from "lunr";

const Sources = ({ data }) => {

  const allCards = [
    ...("allWpDestination" in data ? data?.allWpDestination?.edges || [] : []),
  ]

  const stackPages = {
    "stacks": data.wp.tenjinSettings.acf.stacksPage,
    "destinations": data.wp.tenjinSettings.acf.destinationsPage,
    "sources": data.wp.tenjinSettings.acf.sourcesPage,
    "analyses": data.wp.tenjinSettings.acf.analysesPage,
    "training": data.wp.tenjinSettings.acf.trainingPage
  }

  const stackMenuLabels = {
    "stacks": data.wp.tenjinSettings.acf.stacksMenuLabel,
    "destinations": data.wp.tenjinSettings.acf.destinationsMenuLabel,
    "sources": data.wp.tenjinSettings.acf.sourcesMenuLabel,
    "analyses": data.wp.tenjinSettings.acf.analysesMenuLabel,
    "training": data.wp.tenjinSettings.acf.trainingMenuLabel
  }

  const [filter, setFilter] = useState(null)

  const [categoryFilter, setCategoryFilter] = useState(null)

  const [searchQuery, setSearchQuery] = useState("")

  const [searchIndex, setSearchIndex] = useState(null)
  let cardsToDisplay = [];
  let searchResultCount = null;

  (() => {
    if (searchIndex && searchQuery) {
      cardsToDisplay = searchCards(searchIndex, searchQuery, allCards)
    } else {
      cardsToDisplay = allCards
    }

    cardsToDisplay = filterByCategory(cardsToDisplay, categoryFilter)

    if (filter) {
      sortCards(cardsToDisplay, filter)
    }

    searchResultCount = cardsToDisplay.length
  })()

  useEffect(() => {
    setSearchIndex(buildSearchIndex())
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const buildSearchIndex = () => {
    return lunr(function () {
      this.ref("id")
      this.field("title")

      this.pipeline.remove(lunr.stemmer)
      this.searchPipeline.remove(lunr.stemmer)

      allCards.map(card => {
        return this.add({
          id: card.node.id,
          title: card.node.title,
        })
      })
    })
  }

  const settings = {
    speed: 500,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1.3,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.3,
          arrow: false,
        },
      },
      {
        breakpoint: 1024,
        slidesToShow: 1.3,
        arrow: false,
      },
      {
        breakpoint: 99999999,
        settings: "unslick",
      },
    ],
  }

  return (
    <StacksTemplate
      seoTitle={`${stackPages?.destinations?.title ?? 'Destinations'} | Growth FullStack`}
      seoPost={data.wp.tenjinSettings.acf.destinationsPage}
      stackPages={stackPages}
      stackMenuLabels={stackMenuLabels}
      currentPage='destinations'
      categories={data.allWpDestinationCategory.edges}
      {...{
        data,
        setSearchQuery,
        filter,
        setFilter,
        searchQuery,
        searchResultCount,
        cardsToDisplay,
        settings,
        setCategoryFilter,
        categoryFilter
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    allWpDestination(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          id
          acf {
            iconColor {
              sourceUrl
              mediaDetails {
                height
                width
              }
            }
            iconAlt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPopularity
          }
          title
          nodeType
          uri
          slug
          date
          categories: destinationCategories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    allWpDestinationCategory(filter: { parentId: { eq: null } }) {
      edges {
        node {
          name
          slug
          parentId
          id
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    wp {
      tenjinSettings {
        acf {
          stacksMenuLabel
          destinationsMenuLabel
          sourcesMenuLabel
          trainingMenuLabel
          analysesMenuLabel
          destinationsPage {
            ... on WpPage {
              id
              title
              content
              seo {
                canonical
                cornerstone
                focuskw
                fullHead
                metaDesc
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphAuthor
                opengraphDescription
                opengraphModifiedTime
                opengraphPublishedTime
                opengraphPublisher
                opengraphSiteName
                opengraphTitle
                opengraphType
                opengraphUrl
                readingTime
                title
                twitterDescription
                twitterTitle
              }
            }
          }
          sourcesPage {
            ... on WpPage {
              id
              title
            }
          }
          analysesPage {
            ... on WpPage {
              id
              title
            }
          }
          trainingPage {
            ... on WpPage {
              id
              title
            }
          }
        }
      }
    }
  }
`
export default Sources;
